<script setup>
import { addInAppViewToPath, addQueryParamsToPath } from '@@/utils/CommonUtils';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { defaultTrialText } from '@@/composables/useDefaultTrial';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';

const props = defineProps({
  benefitsText: {
    type: String,
    default: 'Upgrade to All-Access and receive exclusive benefits:',
  },
  headerText: {
    type: String,
    default: 'Want to see more?',
  },
  isGuest: {
    type: Boolean,
    default: true,
  },
  inAppView: {
    type: Boolean,
    default: false,
  },
  registerButtonText: {
    type: String,
    default: defaultTrialText,
  },
  returnTo: {
    type: String,
    default: null,
  },
  showBenefits: {
    type: Boolean,
    default: true,
  },
  smallPadding: {
    type: Boolean,
    default: false,
  },
  source: {
    type: String,
    default: null,
  },
  upgradeReasons: {
    type: Array,
    default() { return []; },
  },
});

const benefitsClass = computed(() => ([
  'tw-hidden',
  props.showBenefits ? 'md:tw-block md:tw-ml-6 lg:tw-ml-12' : null,
]));

const webButtonProps = computed(() => {
  const params = {
    isGuest: props.isGuest,
    query: {
      return_to: props.returnTo,
      source: props.source,
    },
  };

  const upgradeUrl = getStartFreeTrialUrl(params);

  return {
    additionalClassNames: props.isGuest ? 'tw-mb-6' : 'tw-w-full',
    extraLarge: !props.isGuest,
    to: upgradeUrl,
    type: ButtonTypes.upgrade,
  };
});

const appBuyButtonProps = computed(() => {
  const params = {
    return_to: addInAppViewToPath(props.returnTo, true),
    source: props.source,
  };

  // App buttons have to be rendered as regular links to be handled properly by the app
  return {
    additionalClassNames: 'tw-mb-6 tw-w-full',
    href: addQueryParamsToPath('/user/settings/upgrade', params),
    type: ButtonTypes.homePageBuyNow,
  };
});

const containerClass = computed(() => ([
  'tw-flex',
  props.showBenefits ? 'tw-justify-center md:tw-justify-start' : 'tw-justify-center',
  props.smallPadding ? 'tw-p-2' : 'tw-p-7 lg:tw-py-5 lg:tw-px-12',
  'tw-rounded-lg',
  'tw-text-white',
  'all-access-background-color',
]));

const contentClass = computed(() => ([
  'tw-flex tw-flex-col',
  props.showBenefits ? 'md:tw-w-1/2' : null,
]));

const loginUrl = computed(() => addQueryParamsToPath('/user/login', { return_to: props.returnTo }));
</script>

<template>
  <div :class="containerClass">
    <div :class="contentClass">
      <div class="tw-flex tw-items-center tw-justify-center">
        <img
          :class="[
            'tw-h-16 tw-w-16',
            smallPadding ? 'tw-mr-2 lg:tw-mr-4' : 'tw-mr-4',
          ]"
          src="~/assets/img/AllAccessInverted.png"
        >
        <h2 class="tw-text-lg tw-font-normal">
          {{ headerText }}
        </h2>
      </div>
      <!-- Web Buttons for Guest User -->
      <div
        v-if="!inAppView && isGuest"
        class="tw-flex tw-flex-col tw-items-center tw-mt-4"
      >
        <Button v-bind="webButtonProps">
          {{ registerButtonText }}
          <small class="tw-block tw-whitespace-nowrap">No credit card required</small>
        </Button>
        <span v-if="isGuest">
          Already have an account?
          <NuxtLink
            class="tw-underline tw-font-medium"
            :to="loginUrl"
          >
            Log In
          </NuxtLink>
        </span>
      </div>
      <!-- Web Buttons for Free User -->
      <div
        v-if="!inAppView && !isGuest"
        class="tw-flex tw-justify-center tw-items-center tw-mt-4"
      >
        <Button v-bind="webButtonProps">
          Buy Now
        </Button>
      </div>
      <!-- The App looks for certain URLs to handle manually so needs its own buttons -->
      <div
        v-if="inAppView"
        class="tw-flex tw-items-center tw-mt-4"
      >
        <Button v-bind="appBuyButtonProps">
          Buy Now
        </Button>
      </div>
    </div>
    <div
      v-if="showBenefits"
      :class="benefitsClass"
    >
      <p class="tw-mb-2">
        {{ benefitsText }}
      </p>
      <ul class="tw-mb-4 tw-ml-4 tw-list-disc tw-list-outside">
        <li
          v-for="(upgradeReason, index) in upgradeReasons"
          :key="index"
          class="tw-mb-1"
        >
          {{ upgradeReason.title }}
        </li>
      </ul>
    </div>
  </div>
</template>
